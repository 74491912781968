.pulse {
  animation: animate 3s linear infinite;
  --bs-btn-color: #90762C;
  --bs-btn-border-color: #90762C;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #90762C;
  --bs-btn-hover-border-color: #90762C;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #90762C;
  --bs-btn-active-border-color: #90762C;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #90762C;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #90762C;
  --bs-gradient: none;
}

@keyframes animate {
  0% {
    box-shadow: 0 0 0 0 rgb(144, 118, 44, 0.7), 0 0 0 0 rgb(144, 118, 44, 0.7)
  }

  40% {
    box-shadow: 0 0 0 50px rgb(144, 118, 44, 0), 0 0 0 0 rgb(144, 118, 44, 0.7)
  }

  80% {
    box-shadow: 0 0 0 50px rgb(144, 118, 44, 0), 0 0 0 30px rgb(144, 118, 44, 0)
  }

  100% {
    box-shadow: 0 0 0 0 rgb(144, 118, 44, 0), 0 0 0 30px rgb(144, 118, 44, 0)
  }
}
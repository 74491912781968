.rotate3d {
  animation: rotateAnimation 3s infinite;
}

@keyframes rotateAnimation {
  from {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(-180deg);
  }
}
:root {
  --chartHeight: 400px;
  --downloadButtonHeight: 30px;
}

.chartHeight {
  height: var(--chartHeight);
}

.downloadButtonDivHeight {
  height: var(--downloadButtonHeight);
}

.chartBoxHeight {
  height: calc(var(--chartHeight) + var(--downloadButtonHeight));
}

.downloadButton {
  --bs-btn-font-size: 0.7rem;
}